import React from 'react';
import logo from '../static/logo.jpg';
import { Link,NavLink } from 'react-router-dom';

const Navbar=()=>{
return(
    <div className="navbar-fixed">
        <nav >
            <div className="nav-wrapper blue ">
                <Link to="/" id="home" className="btn-floating " title="محمد احمد صفائی" >
                    <img src={logo} height='50px' className="dib br-100 circle " alt="محمد احمد صفائی"/>  
                </Link>
                <ul id="nav-mobile" className="left topnav">
                    <li><NavLink to="/about">درباره من</NavLink></li>
                    <li><NavLink to="/mySkills">مهارت ها</NavLink></li>
                    <li><NavLink to="/projects">پروژه ها</NavLink></li>
                </ul>
            </div>
        </nav>
    </div>
)}

export default Navbar;