import React from 'react';
import gif from '../static/rocket1.gif';

const Home =(props) =>{
    setTimeout(()=>{
        props.history.push('/about');
    },2000);
    return(
        <div className="container center-align ">
            <div className="row">
                    <h4 className="col s12">محمد احمد صفائی</h4>
                    <h6 className="col s12">WebDeveloper</h6>
                    <img className="col s12 "  src={gif} alt="محمد احمد صفائی" />
                
            </div>
        </div>
    );
}

export default Home;