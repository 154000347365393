import React from 'react';
import Navbar from './components/Navbar';
import {BrowserRouter,Route} from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import MySkills from './components/MySkills';
import Projects from './components/Projects';

class App extends React.Component {

  render(){
    return (
      <BrowserRouter>
        <div className="App " >
          <Navbar />
          <Route exact path='/' component={Home}/>
          <Route path='/about' component={About}/>
          <Route path='/mySkills' component={MySkills}/>
          <Route path='/projects' component={Projects}/>
        </div>
      </BrowserRouter>
    );
  }
}

export default App;
