import React from 'react';
import GitHub from '../static/GitHub-Mark.png';
import tele from '../static/telegram.png';
import linkedin from '../static/Linkdin.png';
import gmail from '../static/gmail.png';

const About =() =>{
    
    return(
        <div className="row ">
            <div className="col s12 center-align "><br/><h6>درباره من</h6></div>
                <div className="col s12 m9">
                    <p>
                    سلام من محمد احمد صفائی یک دوستدار برنامه نویسی وب هستم 
                    </p>
                    <p>
                        یاد گرفتن برنامه نویسی بزرگترین رویداد زندگیم بوده و هست
                    </p>
                    <p>
                        یاد گیری لینوکس و اپن سورس بزرگترین چالش برای من بوده
                    </p>
                    <p>
                        ادامه دادن یاد گیری برنامه نویسی بهترین چالش زندگی برای به چالش کشیدن زندگیم می باشد
                    </p>
                    <hr /><h6>دوره ها و آموزش ها گذرانده شده:</h6>
                    <div style={{overflowY: 'scroll',border:'1px solid black',height:'40vh'}}>
                        <p>-1393- کارشناسی نرم افزار کامپیوتر – موسسه آموزش عالی رشدیه تبریز </p>
                        <p>-1935- دوره CCNA - موسسه نورانت</p>
                        <p>-1935- دوره LPIC1 - موسسه فناوری آنیسا </p>
                        <p>-1398- دوره ی آنلاین برنامه نویسی وب</p>
                    </div>
                </div>
            <div className="col s12 m3 center-align">
                <h6>راهای ارتباطی</h6>
                <div className="row">
                    <div className="col s12 m6">
                        <a href="http://t.me/mohamadas1992" target="_blank" rel="noopener noreferrer" title="Telegram"><img src={tele} alt="" height="80" width="80"/></a>
                    </div>
                    <div className="col s12 m6 ">
                        <a href="https://github.com/mohamadas1992/" target="_blank" rel="noopener noreferrer" title="GitHub"><img src={GitHub} alt="" height="80" width="80"/></a>
                    </div>
                    <div className="col s12 m6 ">
                        <a href="mailto:mohamadas1992@gmail.com" target="_blank" rel="noopener noreferrer" title="Gmail"><img src={gmail} alt="" height="80" width="80"/></a>
                    </div>
                    <div className="col s12 m6 ">
                        <a href="https://linkedin.com/in/mohammad-ahmad-safaei-324910133" target="_blank" rel="noopener noreferrer" title="Linkedin"><img src={linkedin} alt="" height="80" width="80"/></a>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;