import React,{Component} from 'react';
import projects from '../api/projects.json';


class Projects extends Component {
    constructor(){
        super();
        this.state={
            project:[]
        }
    }
    componentDidMount(){
        this.setState({project:projects})
    }
    render(){
        const { project }=this.state;
        const proList=project.length ? (
            project.map(pro =>{
                return(
                        <div className="col s12 m4 ">
                            <div className="card" key={pro.id}>
                                <div className="card-image">
                                    <img src={pro.image} alt={pro.title}/>
                                    <span className="card-title blue-text">{pro.title}</span>
                                </div>
                                <div className="card-content">
                                    <p>{pro.content}</p>
                                </div>
                                <div className="card-action">
                                    <a href={pro.action} target="_blank" rel="noopener noreferrer">{pro.title}</a>
                                </div>
                            </div>
                        </div>
                )
            })
        ) : (
            <div className="center-align">لطفا منتظر باشد</div>
        )
          
        return(
            
             <div >
                <h4 className="center-align">نمونه پروژه ها</h4>
                <div className="row center">
                    {proList}
                </div>
            </div>
        );
    }
}


export default Projects;