import React from 'react';

const MySkills =() =>{
    return(
        <div className="container">
            <h5 className="center">مهارت های من</h5>
            <div className="row">
                <div className="col s12 m5">
                <div className="card-panel teal">
                    <span className="white-text center">
                        <h5>Front-end</h5>
                        <hr className="blue" />
                        <h6>HTML</h6>
                        <h6>CSS</h6>
                        <h6>JavaScript(ES6,ES7)</h6>
                        <h6>Bootstrap</h6>
                        <h6>React.js</h6>
                        <h6>Redux</h6>
                        <h6>WordPress</h6>
                    </span> 
                </div>
                </div>
                <div className="col s12 m5">
                <div className="card-panel teal">
                    <span className="white-text center">
                        <h5>Linux & Network</h5>
                        <hr className="blue" />
                        <h6>Introduction to Shell Scripting</h6>
                        <h6>Linux Installation and Package Management</h6>
                        <h6>LAMP & Nginx Installation and Management</h6>
                        <h6>Networking Fundamentals</h6>
                        <h6>MySQL</h6>
                        <h6>Understanding startup and configuration CISCO Routers and Switch</h6>
                    </span>
                </div>
                </div>
                <div className="col s12 m5">
                <div className="card-panel teal">
                    <span className="white-text center">
                        <h5>Other</h5>
                        <hr className="blue" />
                        <h6>C++ (17)</h6>
                        <h6>Introduction to GIT</h6>
                        <h6>Responsive Design</h6>
                        <h6>Mobile Design JAVA</h6>
                        <h6>RESTful APIs</h6>
                        <h6>virtualization</h6>
                        <h6>MaterializeCSS</h6>
                    </span>
                </div>
                </div>
            </div>
        </div>
    );
}

export default MySkills;